<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>限发区管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="add()">新增限发地区</el-button>
        </el-col>
      </el-row>
      <!-- 订单列表数据 -->
      <el-table :data="list" style="width: 100%">
        <el-table-column label="省" prop="prov" />
        <el-table-column label="市" prop="city" >
          <template slot-scope="scope" width>
            {{ scope.row.city || '--' }}
          </template>
        </el-table-column>
        <el-table-column label="区" prop="area" >
          <template slot-scope="scope" width>
            {{ scope.row.area || '--' }}
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作">
          <template slot-scope="props">
            <el-button type="danger" @click="deleteOrder(props.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="50%" @close="editDialogClosed">
      <!-- 主要内容 -->
      <el-form label-width="100px">
        
        <el-form-item label="省" >
          <el-select v-model="provIndex" >
              <el-option v-for="(item,idx) in address" :key="idx" :label="item.province" :value="idx" />
          </el-select>
        </el-form-item>
        <el-form-item label="市" >
          <el-select v-model="city" >
              <el-option v-for="(item,idx) in address[provIndex].city" :key="idx" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="区" >
          <el-input v-model="area" placeholder="区" />
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
//
<script>
export default {
  //                   action="https://adf.lvzhangkeji.com/api/OrderAdminModule/adolf-order/OrderInExcel"

  data () {
    return {
      list: [],
      address: [{city: []}],
      citys: [],
      provIndex: 0,
      city: '--',
      dialogVisible: false,
      area: ''
    }
  },
  created () {
    // 订单列表
    this.GetList();
    this.GetAddressList();
  },
  methods: {
    GetAddressList () {
      this.$api.GetAddressList().then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求失败')
        }
        
        this.address = res.data.map(o => {
          o.city = eval(o.city);
          return o;
        })
      })
    },
    GetList () {
      this.$api.QueryNoOrderAddress({}).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求失败')
        }
       
        this.list = res.data
      })
    },
    search(){
      let {beginDate, endDate} = this.params;
      if((beginDate && !endDate) || (!beginDate && endDate)){
        alert('请输入完成的时间段')
        return
      }
      if(new Date(endDate).getTime() < new Date(beginDate).getTime()){
        alert('结束时间不能小于开始时间')
        return
      }
      this.params.currentPage = 1;
      this.GetOrders();
    },
    deleteOrder(id){
      this.$confirm('确定删除吗？', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.DeleteNoOrderAddress({id: id}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.$message.success('删除成功')
              this.GetList();
            })
          }
        }
      })
    },
    // 点击显示出编辑商品对话框
    add (row) {
      this.dialogVisible = true
    },

    // 监听分类编辑对话框的关闭事件
    editDialogClosed () {
      
    },
    // 编辑商品
    save () {
      let prov = this.address[this.provIndex].province;
      let city = this.city == '--' ? null : this.city;
      this.$api.AddNoOrderAddress({prov: prov, city: city, area: this.area}).then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求失败')
        }
        this.$message.success('保存成功')
        this.dialogVisible = false;
        this.GetList();
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.el-button {
  padding: 10px;
}

.search-input {
  width:150px;
    margin-right: 5px;
}
.search-select{
  width:140px;
  margin-right: 5px;
}
.search-date{
  width:135px;
}
</style>
